import {
	Button,
	Card,
	Descriptions,
	Form,
	Input,
	Popconfirm,
	message,
} from 'antd';
import dayjs from 'dayjs';
import { ReactNode, useState } from 'react';
import { tailLayout } from '../edit/tailLayout';
import { timeDayFormatDefine } from '../overview/listConvertFunc';
import { compact } from 'lodash-es';

export type localType = {
	label: string;
	field: string;
	isKey?: boolean;
	isTitle?: boolean;
	isEdit?: boolean;
	type?: 'time';
}[];

export function SuperDescription({
	obj,
	actions,
	title,
	updateAction,
	deleteAction,
	onFinish,
	newLocal,
	initEdit = false,
}: {
	newLocal: localType;
	obj: any;
	actions: ReactNode[];
	title?: string;
	updateAction?: any;
	deleteAction?: any;
	onFinish: () => void;
	initEdit?: boolean;
}) {
	const aa = updateAction;
	const [isEdit, setEditMode] = useState(initEdit);

	const [form] = Form.useForm();
	const key = newLocal.find((i) => i.isKey);
	const isInternalTitle = newLocal.find((i) => i.isTitle)?.field;
	const mTitle = title ?? (isInternalTitle && obj[isInternalTitle]);
	if (!key) {
		return <div>key not found</div>;
	}

	return (
		<div>
			<Card
				title={mTitle}
				style={{ width: 300 }}
				actions={actions}
				extra={
					<div className="flex flex-row gap-2">
						{editRender(updateAction, setEditMode, isEdit)}

						{deleteRender(async () => {
							const cb = await deleteAction.mutateAsync({
								id: obj[key.field],
							});

							if (cb.numUpdatedRows >= 1n) {
								message.success('更新成功');
								onFinish?.();
								return;
							}

							message.error('更新失败');
						})}
					</div>
				}
			>
				{isEdit ? (
					<Form
						form={form}
						initialValues={obj}
						onFinish={async (value) => {
							console.log(`value`, value);
							try {
								const update = await aa.mutateAsync({
									...obj,
									...value,
								});

								console.log(update);
								onFinish?.();
								message.success('更新完成');
								setEditMode(false);
							} catch (err) {
								message.error(err);
							}
						}}
					>
						{newLocal.map((j) => {
							const { label, field, type, isEdit } = j;
							const val = obj[field];

							return (
								<Form.Item
									key={field}
									label={label}
									name={field}
									getValueProps={(value) => {
										return { value, disabled: !isEdit };
									}}
								>
									<Input />
									{/* {type === 'time' ? <TimePicker /> : <Input />} */}
								</Form.Item>
							);
						})}

						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="submit">
								录入
							</Button>
						</Form.Item>
					</Form>
				) : (
					<Descriptions layout="horizontal" column={1}>
						{newLocal.map((j) => {
							const { label, field, type } = j;
							const val = obj[field];

							return (
								<Descriptions.Item key={label} label={label}>
									{type === 'time'
										? dayjs(obj[field]).format(timeDayFormatDefine)
										: val}
								</Descriptions.Item>
							);
						})}
					</Descriptions>
				)}

				<Button
					className="hidden"
					onClick={() => {
						console.log(form.getFieldsValue());
						console.log(obj);
					}}
				>
					log
				</Button>
			</Card>
		</div>
	);
}
function deleteRender(doCB?: () => void): ReactNode {
	return (
		doCB && (
			<Popconfirm
				title={'确定删除吗'}
				onConfirm={async () => {
					await doCB();
				}}
			>
				<Button type="default">删除</Button>
			</Popconfirm>
		)
	);
}

function editRender(
	updateAction: any,
	setEditMode,
	isEdit: boolean,
): ReactNode {
	return (
		updateAction && (
			<Button
				onClick={() => {
					setEditMode((a) => !a);
				}}
			>
				{isEdit ? '展示' : '编辑'}
			</Button>
		)
	);
}
